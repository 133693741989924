@media (width <= 600px) {
  .ui-ms-landing-stepper {
    display: flex;
    flex-direction: column;
    gap: rem-convert($andes-spacing-16);
    height: auto;
    width: 100%;
  }

  .ui-ms-landing-stepper__images {
    flex: 1;
    padding: rem-convert(0 $andes-spacing-16);
    position: relative;
  }

  .ui-ms-landing-stepper__content-items {
    padding: 0;
  }

  .ui-ms-landing-step__item {
    align-items: start;
    flex-direction: column;
    gap: rem-convert($andes-spacing-16);
    opacity: 1;
    overflow: hidden;
    padding-bottom: rem-convert($andes-spacing-16);
    padding-left: rem-convert($andes-spacing-16);
    transition: opacity 0.3s ease;
    width: 100%;
  }

  .ui-ms-landing-step__tracker {
    background-image: url('~/app/assets/images/home/stepper-track-line-horizontal.svg');
    background-repeat: space;
    left: 0;
    top: rem-convert($mshops-spacing-22);
    width: 100%;
  }

  .ui-ms-landing-step__item:not(.ui-ms-landing-step__item--active) {
    opacity: 0.5;
  }

  .ui-ms-landing-step__item:last-of-type .ui-ms-landing-step__tracker {
    height: rem-convert($mshops-spacing-2);
  }

  .ui-ms-landing-stepper__content-items
    .slick-slide:last-child
    .ui-ms-landing-step__tracker {
    width: 10%;
  }

  .ui-ms-landing-stepper__content-items .slick-slide:first-child {
    padding-left: rem-convert($andes-spacing-16);
  }

  .ui-ms-landing-stepper__content-items
    .slick-slide:first-child
    .ui-ms-landing-step__item {
    padding-left: 0;
  }

  .ui-ms-landing-step__title {
    font-size: rem-convert($andes-spacing-16);
    font-weight: $font-weight-semibold;
    line-height: rem-convert($mshops-line-height-125);
    margin: 0;
    max-width: rem-convert($mshops-step-title-max-width);
    padding-left: rem-convert($andes-spacing-12);
  }

  .ui-ms-landing-stepper__images .slick-dots {
    bottom: rem-convert($mshops-stepper-dots-absolute);
  }

  .ui-ms-landing-step__button {
    font-size: rem-convert($andes-spacing-16);
    font-weight: $font-weight-regular;
    line-height: rem-convert($mshops-line-height-125);
    width: auto;
  }
}
