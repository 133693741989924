@import '../../../../styles/common';

@media (width <= 991px) {
  .faq {
    padding: rem-convert(
      $andes-spacing-64 $andes-spacing-20 $mshops-spacing-120
    );
  }

  .faq__title {
    font-size: rem-convert($font-size-44);
    margin: rem-convert(0 0 $andes-spacing-40);
  }
}
