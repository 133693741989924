@import 'animations/biolibre';
@import 'animations/seo';
@import 'animations/socialmedia';

.ui-ms-landing-toolscard {
  max-width: rem-convert($mshops-toolcard-size-392);
  object-fit: contain;
  overflow: hidden;
}

.ui-ms-landing-toolscard__animation-cont {
  border-radius: rem-convert($mshops-border-radius-30);
  object-fit: contain;
  overflow: hidden;
}

.ui-ms-landing-toolscard__cont-text {
  padding: rem-convert($andes-spacing-40 $andes-spacing-8);
}

.ui-ms-landing-toolscard__title {
  font-size: rem-convert($font-size-24);
  font-weight: $font-weight-semibold;
  line-height: $mshops-line-height-110;
  text-align: center;
}
