/* UI ICON BUTTON LANDING COMMONS */

.ui-ms-landing-icon-button {
  align-items: center;
  border-radius: 50%;
  color: $andes-black;
  display: flex;
  justify-content: center;
  line-height: $mshops-line-height-100;
  text-decoration: none;
  transition-duration: 0.1s;
  transition-property: background-color, color;
  transition-timing-function: ease-out;
}

.ui-ms-landing-icon-button__icon {
  height: rem-convert($andes-spacing-24);
  width: rem-convert($andes-spacing-24);
}

.ui-ms-landing-icon-button--sm {
  height: rem-convert($andes-spacing-32);
  width: rem-convert($andes-spacing-32);
}

.ui-ms-landing-icon-button--lg {
  height: rem-convert($andes-spacing-48);
  width: rem-convert($andes-spacing-48);
}

.ui-ms-landing-icon-button--xlg {
  height: rem-convert($mshops-spacing-70);
  width: rem-convert($mshops-spacing-70);
}

/* VARIANT: PRIMARY */

.ui-ms-landing-icon-button--primary-black {
  background-color: $andes-black;
}

.ui-ms-landing-icon-button--primary-black > .ui-ms-landing-icon-button__icon {
  fill: $andes-white;
}

.ui-ms-landing-icon-button--primary-pink {
  background-color: $mshops-color-pink-700;
}

.ui-ms-landing-icon-button--primary-pink > .ui-ms-landing-icon-button__icon {
  fill: $andes-white;
}

.ui-ms-landing-icon-button--primary-yellow {
  background-color: $mshops-color-yellow-600;
}

.ui-ms-landing-icon-button--primary-yellow > .ui-ms-landing-icon-button__icon {
  fill: $andes-black;
}

.ui-ms-landing-icon-button--primary-white {
  background-color: $andes-white;
}

.ui-ms-landing-icon-button--primary-white > .ui-ms-landing-icon-button__icon {
  fill: $andes-black;
}

.ui-ms-landing-icon-button--primary-white-yellow {
  background-color: $mshops-color-white-30;
}

.ui-ms-landing-icon-button--primary-white-yellow
  > .ui-ms-landing-icon-button__icon {
  fill: $andes-white;
}

.ui-ms-landing-icon-button--primary-black:hover {
  background-color: $mshops-color-gray-900;
}

.ui-ms-landing-icon-button--primary-pink:hover {
  background-color: $mshops-color-pink-900;
}

.ui-ms-landing-icon-button--primary-white:hover {
  background-color: $mshops-color-gray-200;
}

.ui-ms-landing-icon-button--primary-white-yellow:hover {
  background-color: $mshops-color-yellow-600-30;
}

.ui-ms-landing-icon-button--primary-yellow:hover {
  background-color: $mshops-color-yellow-800;
}

/* VARIANT: SECONDARY */

.ui-ms-landing-icon-button--secondary {
  background-color: transparent;
  border-style: solid;
  border-width: rem-convert($mshops-stroke-2);
}

.ui-ms-landing-icon-button--secondary-black {
  border-color: $andes-black;
}

.ui-ms-landing-icon-button--secondary-black > .ui-ms-landing-icon-button__icon {
  fill: $andes-black;
}

.ui-ms-landing-icon-button--secondary-pink {
  border-color: $mshops-color-pink-700;
}

.ui-ms-landing-icon-button--secondary-pink > .ui-ms-landing-icon-button__icon {
  fill: $mshops-color-pink-700;
}

.ui-ms-landing-icon-button--secondary-yellow {
  border-color: $mshops-color-yellow-600;
}

.ui-ms-landing-icon-button--secondary-yellow
  > .ui-ms-landing-icon-button__icon {
  fill: $mshops-color-yellow-600;
}

.ui-ms-landing-icon-button--secondary-white {
  border-color: $andes-white;
}

.ui-ms-landing-icon-button--secondary-white > .ui-ms-landing-icon-button__icon {
  fill: $andes-white;
}

.ui-ms-landing-icon-button--secondary-black:hover {
  background-color: $andes-black;
}

.ui-ms-landing-icon-button--secondary-black:hover
  .ui-ms-landing-icon-button__icon {
  fill: $andes-white;
}

.ui-ms-landing-icon-button--secondary-pink:hover {
  background-color: $mshops-color-pink-700;
}

.ui-ms-landing-icon-button--secondary-pink:hover
  > .ui-ms-landing-icon-button__icon {
  fill: $andes-white;
}

.ui-ms-landing-icon-button--secondary-yellow:hover {
  background-color: $mshops-color-yellow-600;
}

.ui-ms-landing-icon-button--secondary-yellow:hover
  > .ui-ms-landing-icon-button__icon {
  fill: $andes-black;
}

.ui-ms-landing-icon-button--secondary-white:hover {
  background-color: $andes-white;
}

.ui-ms-landing-icon-button--secondary-white:hover
  > .ui-ms-landing-icon-button__icon {
  fill: $andes-black;
}
