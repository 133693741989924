@media (width <= 600px) {
  .ui-ms-landing-icon-number--lg {
    font-size: rem-convert($font-size-24);
    height: rem-convert($mshops-icon-number-size-56);
    width: rem-convert($mshops-icon-number-size-56);
  }

  .ui-ms-landing-icon-number--md {
    font-size: rem-convert($font-size-18);
    height: rem-convert($mshops-icon-number-size-44);
    width: rem-convert($mshops-icon-number-size-44);
  }

  .ui-ms-landing-icon-number--sm {
    font-size: rem-convert($font-size-18);
    height: rem-convert($mshops-icon-number-size-44);
    width: rem-convert($mshops-icon-number-size-44);
  }
}
