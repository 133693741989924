// colors
$mshops-color-blue-500: #0a0080;
$mshops-color-blue-100: #4785f2;
$mshops-color-blue-700: #2d3277;
$mshops-color-gray-100: #eeeff0;
$mshops-color-gray-150: rgba(195 195 195 / 85.5%);
$mshops-color-gray-200: #dadcde;
$mshops-color-gray-200-50: rgba(218 220 222 / 50%);
$mshops-color-gray-500: #8a8c92;
$mshops-color-gray-750: #404040;
$mshops-color-gray-800: #333;
$mshops-color-gray-900: #222;
$mshops-color-gray: #666;
$mshops-color-pink-700: #e73b90;
$mshops-color-pink-900: #c4327a;
$mshops-color-pink-950: #771847;
$mshops-color-white-30: rgba(255 255 255 / 30%);
$mshops-color-yellow-100: #ffd100;
$mshops-color-yellow-600-30: rgba(255 230 0 / 30%);
$mshops-color-yellow-600: #ffe600;
$mshops-color-yellow-800: #d9c400;

// font size
$mshops-font-size-170: 170px;
$mshops-font-size-138: 138px;
$mshops-font-size-110: 110px;
$mshops-font-size-86: 86px;
$mshops-font-size-80: 80px;
$mshops-font-size-86: 86px;
$mshops-font-size-64: 64px;
$mshops-font-size-56: 56px;
$mshops-font-size-44: 44px;
$mshops-font-size-40: 40px;
$mshops-font-size-24: 24px;
$mshops-font-size-13: 13px;

// line height
$mshops-line-height-100: 1;
$mshops-line-height-110: 1.1;
$mshops-line-height-120: 1.2;
$mshops-line-height-125: 1.25;

// font weight
$font-weight-black: 800 !default;

// border radius
$mshops-border-radius-15: 15px;
$mshops-border-radius-30: 30px;
$mshops-border-radius-60: 60px;

// border
$mshops-stroke-1: 1px;
$mshops-stroke-2: 2px;
$mshops-stroke-124: 1.24px;
$mshops-avatar-size: 56px;

// icon number sizes
$mshops-icon-number-size-70: 70px;
$mshops-icon-number-size-56: 56px;
$mshops-icon-number-size-44: 44px;
$mshops-icon-size-40: 40px;

// cards sizes
$mshops-floatingcard-218: 218px;
$mshops-floatingcard-290: 290px;
$mshops-floatingcard-136: 136px;
$mshops-floatingcard-113: 113px;
$mshops-card-cta-height-mobile: 444px;
$mshops-card-cta-width: 1300px;
$mshops-card-cta-width-text: 614px;
$mshops-card-cta-height: 800px;
$mshops-testimonial-card-418: 418px;
$mshops-testimonial-card-height-150: 150px;
$mshops-testimonial-card-height-125: 125px;
$mshops-toolcard-size-392: 392px;
$mshops-toolcard-size-328: 328px;
$mshops-toolcard-animation-size-315: 315px;
$mshops-toolcard-image-size-267: 267px;
$mshops-toolcard-pill-size-331: 331px;
$mshops-toolcard-social-circle-246: 246px;
$mshops-info-card-max-width-lg: 392px;
$mshops-info-card-max-width-md: 294px;
$mshops-info-card-max-height-sm: 472px;

// price table
$mshops-price-table-max-width: 787px;
$mshops-price-table-item-width: 450px;
$mshops-price-table-item-height: 72px;
$mshops-card-cta-min-height: 720px;

// comparative table
$mshops-comparative-table-max-width-md: 980px;

// containers
$mshops-container-1366: 1366px;
$mshops-container-1280: 1280px;
$mshops-container-1107: 1107px;
$mshops-container-900: 900px;
$mshops-container-716: 716px;
$mshops-container-480: 480px;
$mshops-container-986: 986px;

// FAQAccordion
$mshops-faq-accordion-max-width: 989px;

// Stepper
$mshops-stepper-max-width: 1312px;
$mshops-step-max-width: 648px;
$mshops-step-title-max-width: 284px;
$mshops-stepper-dots-absolute: -220px;
$mshops-stepper-image-height: 649px;

// spacing
$mshops-spacing-1: 1px;
$mshops-spacing-2: 2px;
$mshops-spacing-3: 3px;
$mshops-spacing-15: 15px;
$mshops-spacing-22: 22px;
$mshops-spacing-26: 26px;
$mshops-spacing-29: 29px;
$mshops-spacing-30: 30px;
$mshops-spacing-36: 36px;
$mshops-spacing-44: 44px;
$mshops-spacing-52: 52px;
$mshops-spacing-70: 70px;
$mshops-spacing-80: 80px;
$mshops-spacing-96: 96px;
$mshops-spacing-104: 104px;
$mshops-spacing-100: 100px;
$mshops-spacing-120: 120px;
$mshops-spacing-144: 144px;
$mshops-spacing-152: 152px;
$mshops-spacing-168: 168px;

// ecosystem
$mshops-ecosystem-height: 1000px;
$mshops-ecosystem-title-max-width: 652px;
$mshops-ecosystem-subtitle-max-width-desktop: 940px;
$mshops-ecosystem-subtitle-max-width-mobile: 343px;
$mshops-ecosystem-card-size-desktop: 218px;
$mshops-ecosystem-card-size-mobile: 136px;

// Cases
$mshops-cases-max-width: 1312px;
$mshops-cases-title-max-width: 940px;
$mshops-cases-subtitle-max-width-desktop: 940px;
$mshops-cases-subtitle-max-width-mobile: 343px;
$mshops-cases-card-size-desktop: 218px;
$mshops-cases-card-size-mobile: 136px;

// tabs
$mshops-tabs-max-width: 380px;

// tools
$mshops-tools-max-width: 1248px;

// banner card
$mshops-banner-card-max-width: 1217px;
$mshops-banner-card-text-width: 673px;

// Hero Pro
$mshops-hero-pro-logo-desktop-lg: 258px;
$mshops-hero-pro-logo-desktop-md: 223px;
$mshops-hero-pro-logo-mobile: 171px;
$mshops-hero-pro-text-box: 650px;
$mshops-hero-pro-img-desktop: url('~/app/assets/images/pro/hero-pro-desktop.png');
$mshops-hero-pro-img-mobile: url('~/app/assets/images/pro/hero-pro-mobile.png');
$mshops-hero-pro-title-box: 811px;

// testimonials
$mshops-testimonial-pro-image-max-width-lg: 980px;
$mshops-testimonial-pro-image-max-width-md: 735px;
$mshops-testimonial-pro-content-max-width: 981px;

// Integrations
$mshops-integrations-title-max-width: 671px;
