@import '../../../../styles/common';

.ui-ms-landing-create-your-shop {
  align-items: center;
  background-color: $andes-white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: rem-convert($andes-spacing-64 0);
  position: relative;
  width: 100%;
}

.ui-ms-landing-create-your-shop__container--sticky {
  left: 0;
  padding-top: rem-convert($mshops-spacing-152);
  position: sticky;
  top: 0;
  transition: all 0.3s ease;
  z-index: 9;
}

.ui-ms-landing-create-your-shop__sticky-step {
  height: 100vh;
  width: 100vh;
}

.ui-ms-landing-create-your-shop__title {
  font-size: rem-convert($mshops-font-size-86);
  font-weight: $font-weight-black;
  line-height: $mshops-line-height-100;
  max-width: rem-convert($mshops-container-1107);
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
}
