@import '../../../../styles/common';

@media (width <= 991px) {
  .ui-ms-landing-chart__container {
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-40;
    padding: rem-convert($andes-spacing-64 $andes-spacing-20);
  }

  .ui-ms-landing-chart__title {
    font-size: rem-convert($mshops-font-size-56);
  }

  .ui-ms-landing-chart__subtitle {
    font-size: rem-convert($mshops-font-size-24);
    margin-bottom: rem-convert($andes-spacing-32);
  }

  .ui-ms-landing-chart__copy {
    font-size: rem-convert($font-size-24);
    padding: 0;
  }
}
