@import '../../../../styles/common';

@media (width <= 600px) {
  .ui-ms-landing-cases {
    align-items: unset;
    gap: rem-convert($andes-spacing-64);
    height: auto;
    min-height: 100%;
    padding: rem-convert($andes-spacing-64 0 $mshops-spacing-104);
  }

  .ui-ms-landing-cases__carousel {
    padding: rem-convert(0 0 0 $andes-spacing-16);
  }

  .ui-ms-landing-cases .ui-ms-landing-card {
    display: flex;
    flex: 1;
    justify-content: unset;
    max-width: 100%;
    transition: unset;
  }

  .ui-ms-landing-cases__title {
    font-size: rem-convert($mshops-font-size-44);
    line-height: rem-convert($mshops-line-height-100);
  }
}
