@import '../../../../styles/common';

@media (width <= 991px) {
  .ui-ms-landing-estilo {
    padding-bottom: 0;
  }

  .ui-ms-landing-estilo__content {
    flex-direction: column;
    margin-bottom: rem-convert($andes-spacing-40);
    padding: rem-convert($andes-spacing-20);
  }

  .ui-ms-landing-estilo__title {
    font-size: rem-convert($mshops-font-size-56);
    margin-bottom: rem-convert($andes-spacing-16);
  }

  .ui-ms-landing-estilo__title-col,
  .ui-ms-landing-estilo__text-col {
    width: 100%;
  }

  .ui-ms-landing-estilo__text {
    font-size: rem-convert($font-size-16);
    margin-bottom: rem-convert($andes-spacing-24);
  }

  .ui-ms-landing-estilo__image {
    max-width: rem-convert(600px);
  }
}
