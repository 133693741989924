.ui-ms-landing-card-with-cta {
  background: none;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.ui-ms-landing-card-with-cta__background-image {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.ui-ms-landing-card-with-cta__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.ui-ms-landing-card-with-cta__container-bg {
  align-items: flex-start;
  background: rgba(0 0 0 / 40%);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  max-height: rem-convert($mshops-card-cta-height);
  min-height: rem-convert($mshops-card-cta-min-height);
  padding: rem-convert($andes-spacing-24 $mshops-spacing-44);
  width: 100%;
}

.ui-ms-landing-card-with-cta__text-content {
  max-width: rem-convert($mshops-card-cta-width-text);
  width: 100%;
}

.ui-ms-landing-card-with-cta__title {
  color: $andes-white;
  font-size: rem-convert($font-size-32);
  font-weight: $font-weight-black;
  line-height: $mshops-line-height-110;
  margin-bottom: rem-convert($andes-spacing-20);
}

.ui-ms-landing-card-with-cta__description {
  color: $andes-white;
  font-size: rem-convert($mshops-font-size-24);
  font-weight: $font-weight-regular;
  line-height: $mshops-line-height-110;
  margin-bottom: rem-convert($andes-spacing-20);
}

.ui-ms-landing-card-with-cta__button {
  font-weight: $font-weight-bold;
}
