@import '../../../../styles/common';

.ui-ms-landing-hero {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: rem-convert($mshops-spacing-168 $mshops-spacing-120);
  position: relative;
  width: 100%;
}

.ui-ms-landing-hero__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: rem-convert($mshops-spacing-30);
  margin: 0 auto;
  max-width: rem-convert($mshops-container-986);
  padding: rem-convert(0 $mshops-spacing-30);
  text-align: center;
}

.ui-ms-landing-hero__hover-area {
  bottom: 0;
  height: 85%;
  position: absolute;
  width: 100%;
  z-index: 3;
}

.ui-ms-landing-hero__hover-area--card {
  border: 1px solid transparent;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transform: scale(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out, left 0.1s ease-out,
    top 0.1s ease-out;
  z-index: 1;
}

.ui-ms-landing-hero__hover-area--card--visible {
  opacity: 0.9;
  transform: scale(1);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out, left 0.1s ease-out,
    top 0.1s ease-out;
}

.ui-ms-landing-hero__hover-area-floating-card {
  display: none;
}

.ui-ms-landing-hero__hover-area-floating-card--visible {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.ui-ms-landing-hero__hover-area-floating-card--hide {
  display: none;
  opacity: 0;
}

.ui-ms-landing-hero__cols {
  display: flex;
  height: 100%;
  justify-content: space-around;
}

.ui-ms-landing-hero__cols--col {
  height: 100%;
  width: 33.3%;
}

.ui-ms-landing-hero__title {
  font-size: rem-convert($mshops-font-size-138);
  font-weight: $font-weight-black;
  line-height: $mshops-line-height-100;
  text-transform: uppercase;
}

.ui-ms-landing-hero__subtitle {
  font-size: rem-convert($font-size-32);
  font-weight: $font-weight-black;
  line-height: $mshops-line-height-125;
  text-transform: uppercase;
}

.ui-ms-landing-hero__copy {
  font-size: rem-convert($font-size-32);
  font-weight: $font-weight-regular;
  line-height: $mshops-line-height-125;
  padding: rem-convert(0 $andes-spacing-64);
}

.ui-ms-landing-hero__button--z {
  z-index: 9;
}
