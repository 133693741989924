.ui-ms-landing-tabs {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ui-ms-landing-tabs__list {
  background-color: $mshops-color-gray-100;
  border-radius: rem-convert($mshops-border-radius-60);
  display: inline-flex;
  overflow: hidden;
  padding: rem-convert($andes-spacing-8);
  position: relative;
}

.ui-ms-landing-tabs__pill {
  background-color: $mshops-color-yellow-600;
  border-radius: rem-convert($mshops-border-radius-60);
  bottom: 0;
  margin: rem-convert($andes-spacing-8);
  position: absolute;
  top: 0;
  transition: left 0.3s, width 0.3s;
}

.ui-ms-landing-tabs__panel {
  min-height: 50vh;
  opacity: 1;
  padding: rem-convert($andes-spacing-32 0);
  transition: opacity 0.3s ease-in-out;
  width: 100%;
}

.ui-ms-landing-tabs__panel--fade {
  opacity: 0;
}

.ui-ms-landing-tabs__panel--visible {
  display: block;
}

.ui-ms-landing-tabs__panel--hidden {
  display: none;
}

.ui-ms-landing-tabs__tab {
  background: none;
  border: none;
  color: $mshops-color-gray-500;
  cursor: pointer;
  font-family: $font-family-primary;
  font-size: rem-convert($font-size-18);
  font-weight: $font-weight-bold;
  line-height: $mshops-line-height-125;
  max-width: rem-convert($mshops-tabs-max-width);
  overflow: hidden;
  padding: rem-convert($andes-spacing-16 $andes-spacing-32);
  text-align: center;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;
  z-index: 1;
}

.ui-ms-landing-tabs__tab--active {
  color: $andes-black;
}

@media (width >= 1600px) {
  .ui-ms-landing-tabs__tab {
    font-size: rem-convert($andes-spacing-24);
  }

  .ui-ms-landing-tabs__panel {
    padding: rem-convert($andes-spacing-48 0);
  }
}
