.social-media {
  align-items: center;
  background: $andes-white;
  display: flex;
  flex-direction: column;
  height: rem-convert($mshops-toolcard-animation-size-315);
  justify-content: center;
  position: relative;
  width: 100%;
}

.social-media__circle {
  border-radius: 50%;
  border: rem-convert($mshops-stroke-1) solid $mshops-color-gray-200;
  box-shadow: 0 0 rem-convert($mshops-stroke-1) 0 $mshops-color-gray-100;
  height: rem-convert($mshops-toolcard-social-circle-246);
  object-fit: contain;
  overflow: hidden;
  position: relative;
  width: rem-convert($mshops-toolcard-social-circle-246);
}

.social-media__logos-cont {
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
}

.social-media__logos {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: rem-convert($mshops-toolcard-social-circle-246);
  justify-content: center;
  width: rem-convert($mshops-toolcard-social-circle-246);
}

.social-media__logos-animation {
  animation: animate-logos 15s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes animate-logos {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  3%,
  18% {
    opacity: 1;
    transform: translateX(0);
  }

  19% {
    opacity: 0;
    transform: translateX(0);
  }

  20% {
    opacity: 0;
    transform: translateX(-246px);
  }

  21%,
  36% {
    opacity: 1;
    transform: translateX(-246px);
  }

  37% {
    opacity: 0;
    transform: translateX(-246px);
  }

  38% {
    opacity: 0;
    transform: translateX(-492px);
  }

  39%,
  54% {
    opacity: 1;
    transform: translateX(-492px);
  }

  55% {
    opacity: 0;
    transform: translateX(-492px);
  }

  56% {
    opacity: 0;
    transform: translateX(-738px);
  }

  57%,
  72% {
    opacity: 1;
    transform: translateX(-738px);
  }

  73% {
    opacity: 0;
    transform: translateX(-738px);
  }

  74% {
    opacity: 0;
    transform: translateX(-984px);
  }

  75%,
  95% {
    opacity: 1;
    transform: translateX(-984px);
  }

  100% {
    opacity: 0;
    transform: translateX(-984px);
  }
}
