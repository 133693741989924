.ui-ms-landing-card {
  display: block;
  width: fit-content;
}

// image
.ui-ms-landing-card__image {
  background-size: cover;
  background-repeat: no-repeat;
}

// background
.ui-ms-landing-card__primary-yellow {
  background-color: $mshops-color-yellow-600;
  color: $andes-black;
}

.ui-ms-landing-card__primary-pink {
  background-color: $mshops-color-pink-700;
  color: $andes-black;
}

.ui-ms-landing-card__primary-black {
  background-color: $andes-black;
  color: $andes-white;
}

.ui-ms-landing-card__primary-blue {
  background-color: $mshops-color-blue-500;
  color: $andes-white;
}

.ui-ms-landing-card__primary-white {
  background-color: $andes-white;
  color: $andes-black;
}

.ui-ms-landing-card__primary-transparent {
  background-color: transparent;
  color: $andes-black;
}

// radius
.ui-ms-landing-card__border-radius-sm {
  border-radius: rem-convert($mshops-border-radius-15);
}

.ui-ms-landing-card__border-radius-md {
  border-radius: rem-convert($mshops-border-radius-30);
}

.ui-ms-landing-card__border-radius-lg {
  border-radius: rem-convert($mshops-border-radius-60);
}
