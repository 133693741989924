.ui-ms-landing-icon-number {
  align-items: center;
  border-radius: 50%;
  color: $andes-black;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem-convert($font-size-24);
  font-weight: $font-weight-semibold;
  height: rem-convert($mshops-icon-number-size-70);
  justify-content: center;
  line-height: $mshops-line-height-100;
  width: rem-convert($mshops-icon-number-size-70);
}

.ui-ms-landing-icon-number--filled--yellow {
  background-color: $mshops-color-yellow-600;
}

.ui-ms-landing-icon-number--outlined--yellow {
  border: rem-convert($mshops-stroke-2) solid $mshops-color-yellow-600;
}

.ui-ms-landing-icon-number--filled--black {
  color: $andes-white;
  background-color: $andes-black;
}

.ui-ms-landing-icon-number--outlined--black {
  border: rem-convert($mshops-stroke-2) solid $andes-black;
}

.ui-ms-landing-icon-number--filled--white {
  background-color: $andes-white;
}

.ui-ms-landing-icon-number--outlined--white {
  color: $andes-white;
  border: rem-convert($mshops-stroke-2) solid $andes-white;
}

.ui-ms-landing-icon-number--filled--pink {
  background-color: $mshops-color-pink-700;
}

.ui-ms-landing-icon-number--outlined--pink {
  border: rem-convert($mshops-stroke-2) solid $mshops-color-pink-700;
}

.ui-ms-landing-icon-number--md {
  font-size: rem-convert($font-size-18);
  height: rem-convert($mshops-spacing-52);
  width: rem-convert($mshops-spacing-52);
}

@media (width >= 1600px) {
  .ui-ms-landing-icon-number--md {
    font-size: rem-convert($font-size-20);
    height: rem-convert($andes-spacing-64);
    width: rem-convert($andes-spacing-64);
  }
}
