@import '../../../../styles/common';

.ui-ms-landing-tools {
  align-items: center;
  background: $andes-black;
  display: flex;
  flex-direction: column;
  padding: rem-convert($mshops-spacing-168 0);
  width: 100%;
}

.ui-ms-landing-tools__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: rem-convert($mshops-spacing-120);
}

.ui-ms-landing-tools__carousel-container {
  max-width: rem-convert($mshops-tools-max-width);
}

.ui-ms-landing-tools__text-container {
  display: flex;
  flex-direction: column;
  gap: rem-convert($andes-spacing-64);
  max-width: rem-convert($mshops-container-986);
  padding: 0 rem-convert($andes-spacing-16);
  text-align: center;
}

.ui-ms-landing-tools__title {
  color: $andes-white;
  font-size: rem-convert($mshops-font-size-56);
  font-weight: $font-weight-bold;
  line-height: $mshops-line-height-100;
}

.ui-ms-landing-tools__description {
  color: $andes-white;
  font-size: rem-convert($font-size-32);
  font-weight: $font-weight-regular;
  line-height: $mshops-line-height-110;
}
