@media (width <= 600px) {
  .ui-ms-landing-testimonial-card__text {
    font-size: rem-convert($font-size-20);
    min-height: rem-convert($mshops-testimonial-card-height-125);
  }

  .ui-ms-landing-testimonial-card {
    padding: rem-convert(
      $andes-spacing-32 $andes-spacing-32 $mshops-spacing-52
    );
  }

  .ui-ms-landing-testimonial-card__stars {
    margin-bottom: rem-convert($andes-spacing-48);
  }
}
