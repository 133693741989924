.ui-ms-landing-floating-card {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
}

.ui-ms-landing-floating-card--lg {
  width: rem-convert($mshops-floatingcard-290);
}

.ui-ms-landing-floating-card--md,
.ui-ms-landing-floating-card--sm {
  width: rem-convert($mshops-floatingcard-218);
}

.ui-ms-landing-floating-card__image {
  width: 100%;
}

.ui-ms-landing-floating-card--opacity {
  opacity: 0.9;
}
