@media (width <= 600px) {
  .ui-ms-landing-price-table-container {
    border-radius: rem-convert($mshops-border-radius-15);
    max-width: 100%;
  }

  .ui-ms-landing-price-table__items {
    grid-template-columns: 70% 1fr;
  }

  .ui-ms-landing-price-table__item-title {
    font-size: rem-convert($font-size-16);
    line-height: $mshops-line-height-110;
    padding-left: rem-convert($andes-spacing-20);
  }

  .ui-ms-landing-price-table__item-value {
    font-size: rem-convert($font-size-16);
    line-height: $mshops-line-height-110;
    padding-inline: rem-convert($andes-spacing-12);
  }

  .ui-ms-landing-price-table__items:first-child
    .ui-ms-landing-price-table__item-title,
  .ui-ms-landing-price-table__items:first-child
    .ui-ms-landing-price-table__item-value {
    height: rem-convert(
      calc($mshops-price-table-item-height + $andes-spacing-12)
    );
    margin-top: 0;
    padding-top: rem-convert($mshops-spacing-29);
  }

  .ui-ms-landing-price-table__items:last-child
    .ui-ms-landing-price-table__item-title,
  .ui-ms-landing-price-table__items:last-child
    .ui-ms-landing-price-table__item-value {
    height: rem-convert(
      calc($mshops-price-table-item-height + $andes-spacing-12)
    );
    margin-top: 0;
    padding-bottom: rem-convert($mshops-spacing-29);
  }

  .ui-ms-landing-price-table__item-value--icon {
    width: $andes-spacing-24;
    height: $andes-spacing-24;
  }
}
