@import '../../../../styles/common';

.ui-ms-landing-cases {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: rem-convert($mshops-spacing-120);
  padding: rem-convert($mshops-spacing-168 0);
  min-height: 100vh;
  position: relative;
}

.ui-ms-landing-cases__title {
  align-items: center;
  font-size: rem-convert($mshops-font-size-56);
  font-weight: $font-weight-bold;
  line-height: $mshops-line-height-100;
  max-width: rem-convert($mshops-container-1366);
  text-align: center;
}

.ui-ms-landing-cases__card-container {
  display: flex;
  flex-wrap: wrap;
  gap: rem-convert($andes-spacing-24);
  justify-content: center;
  max-width: rem-convert($mshops-cases-max-width);
  width: 100%;
}

.ui-ms-landing-cases .ui-ms-landing-card {
  display: flex;
  flex: 1 0 30%;
  justify-content: center;
  max-width: 30%;
  transition: all 1s 0.3s;
}

.ui-ms-landing-cases__card-container .ui-ms-landing-card:nth-child(1) {
  transform: translate(91%, 22%);
  z-index: 1;
}

.ui-ms-landing-cases__card-container .ui-ms-landing-card:nth-child(2) {
  transform: translate(22%, 35%);
  z-index: 2;
}

.ui-ms-landing-cases__card-container .ui-ms-landing-card:nth-child(3) {
  transform: translate(-67%, 55%);
  z-index: 3;
}

.ui-ms-landing-cases__card-container .ui-ms-landing-card:nth-child(4) {
  transform: translate(-1%, -37%);
  z-index: 4;
}

.ui-ms-landing-cases__card-container .ui-ms-landing-card:nth-child(5) {
  transform: translate(-47%, -20%);
  z-index: 5;
}

.ui-ms-landing-cases--visible
  .ui-ms-landing-cases__card-container
  .ui-ms-landing-card:nth-child(1) {
  transform: translate(0);
}

.ui-ms-landing-cases--visible
  .ui-ms-landing-cases__card-container
  .ui-ms-landing-card:nth-child(2) {
  transform: translate(0);
}

.ui-ms-landing-cases--visible
  .ui-ms-landing-cases__card-container
  .ui-ms-landing-card:nth-child(4) {
  transform: translate(0);
}

.ui-ms-landing-cases--visible
  .ui-ms-landing-cases__card-container
  .ui-ms-landing-card:nth-child(3) {
  transform: translate(0);
}

.ui-ms-landing-cases--visible
  .ui-ms-landing-cases__card-container
  .ui-ms-landing-card:nth-child(5) {
  transform: translate(0);
}
