/* UI BUTTON LANDING COMMONS */

.ui-ms-landing-button {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: $font-family-primary;
  font-weight: $font-weight-semibold;
  gap: rem-convert($andes-spacing-16);
  line-height: $mshops-line-height-100;
  transition-property: background-color, color;
  transition-duration: 0.18s;
  transition-timing-function: ease-out;
}

.ui-ms-landing-button__icon {
  display: flex;
  align-items: center;
}

.ui-ms-landing-button__text {
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.ui-ms-landing-button__icon--right {
  width: rem-convert($andes-spacing-12);
  height: rem-convert($andes-spacing-12);
}

.ui-ms-landing-button__size--lg {
  font-size: rem-convert($font-size-20);
  height: rem-convert($andes-spacing-64);
}

.ui-ms-landing-button__size--sm {
  font-size: rem-convert($font-size-16);
  height: rem-convert($andes-spacing-48);
}

/* TYPE: PRIMARY */

.ui-ms-landing-button__primary {
  align-items: center;
  justify-content: center;
  border-radius: rem-convert($mshops-border-radius-30);
  padding: rem-convert($andes-spacing-20 $andes-spacing-40);
}

.ui-ms-landing-button__primary--yellow {
  background-color: $mshops-color-yellow-600;
  color: $andes-black;
}

.ui-ms-landing-button__primary--yellow:hover {
  background-color: $mshops-color-yellow-800;
}

.ui-ms-landing-button__primary--pink {
  background-color: $mshops-color-pink-700;
  color: $andes-white;
}

.ui-ms-landing-button__primary--pink:hover {
  background-color: $mshops-color-pink-900;
}

.ui-ms-landing-button__primary--black {
  background-color: $andes-black;
  color: $andes-white;
}

.ui-ms-landing-button__primary--black:hover {
  background-color: $mshops-color-gray-900;
}

.ui-ms-landing-button__primary--pink .ui-ms-landing-button__icon--right,
.ui-ms-landing-button__primary--black .ui-ms-landing-button__icon--right {
  fill: $andes-white;
}

.ui-ms-landing-button__primary--white:hover {
  background-color: $mshops-color-gray-200;
}

/* VARIANT: SECONDARY */

.ui-ms-landing-button__secondary {
  background-color: transparent;
  border-radius: rem-convert($mshops-border-radius-30);
  border-style: solid;
  border-width: rem-convert($mshops-stroke-2);
  color: $andes-black;
  justify-content: center;
  padding: rem-convert($andes-spacing-20 $andes-spacing-40);
}

.ui-ms-landing-button__secondary--yellow {
  border-color: $mshops-color-yellow-600;
}

.ui-ms-landing-button__secondary--yellow .ui-ms-landing-button__icon--right,
.ui-ms-landing-button__secondary--pink .ui-ms-landing-button__icon--right,
.ui-ms-landing-button__secondary--white .ui-ms-landing-button__icon--right {
  fill: $andes-black;
}

.ui-ms-landing-button__secondary--yellow:hover {
  background-color: $mshops-color-yellow-800;
}

.ui-ms-landing-button__secondary--pink {
  border-color: $mshops-color-pink-700;
}

.ui-ms-landing-button__secondary--pink:hover {
  background-color: $mshops-color-pink-900;
  color: $andes-white;
}

.ui-ms-landing-button__secondary--black {
  border-color: $andes-black;
}

.ui-ms-landing-button__secondary--white .ui-ms-landing-button__icon--right {
  fill: $andes-white;
}

.ui-ms-landing-button__tertiary--yellow .ui-ms-landing-button__icon--right {
  fill: $mshops-color-yellow-600;
}

.ui-ms-landing-button__tertiary--pink .ui-ms-landing-button__icon--right {
  fill: $mshops-color-pink-700;
}

.ui-ms-landing-button__tertiary--white .ui-ms-landing-button__icon--right {
  fill: $andes-white;
}

.ui-ms-landing-button__secondary--black:hover {
  background-color: $andes-black;
  color: $andes-white;
}

.ui-ms-landing-button__secondary--black:hover
  > .ui-ms-landing-button__icon--right {
  fill: $andes-white;
}

.ui-ms-landing-button__secondary--white {
  border-color: $andes-white;
  color: $andes-white;
}

.ui-ms-landing-button__secondary--white:hover {
  background-color: $andes-white;
  color: $andes-black;
}

.ui-ms-landing-button__secondary--pink:hover .ui-ms-landing-button__icon--right,
.ui-ms-landing-button__secondary--white:hover
  .ui-ms-landing-button__icon--right {
  fill: $andes-white;
}

.ui-ms-landing-button__secondary--white:hover
  .ui-ms-landing-button__icon--right {
  fill: $andes-black;
}

/* VARIANT: TERTIARY */

.ui-ms-landing-button__tertiary {
  background: transparent;
  padding: 0;
}

.ui-ms-landing-button__tertiary--yellow {
  color: $mshops-color-yellow-600;
}

.ui-ms-landing-button__tertiary--yellow:hover {
  color: $mshops-color-yellow-800;
}

.ui-ms-landing-button__tertiary--yellow:hover
  .ui-ms-landing-button__icon--right {
  fill: $mshops-color-yellow-800;
}

.ui-ms-landing-button__tertiary--pink {
  color: $mshops-color-pink-700;
}

.ui-ms-landing-button__tertiary--pink:hover {
  color: $mshops-color-pink-900;
}

.ui-ms-landing-button__tertiary--pink:hover .ui-ms-landing-button__icon--right {
  fill: $mshops-color-pink-900;
}

.ui-ms-landing-button__tertiary--black {
  color: $andes-black;
}

.ui-ms-landing-button__tertiary--black:hover {
  color: $mshops-color-gray-900;
}

.ui-ms-landing-button__tertiary--white {
  color: $andes-white;
}

.ui-ms-landing-button__tertiary--white:hover {
  color: $mshops-color-gray-500;
}

.ui-ms-landing-button__tertiary--white:hover
  .ui-ms-landing-button__icon--right {
  fill: $mshops-color-gray-500;
}

.ui-ms-landing-button__tertiary-size--sm {
  font-size: rem-convert($font-size-16);
}

.ui-ms-landing-button__tertiary-size--lg {
  font-size: rem-convert($font-size-32);
}
