.ui-ms-landing-testimonial-card {
  max-width: rem-convert($mshops-testimonial-card-418);
  padding: rem-convert($andes-spacing-40);
  width: 100%;
}

.ui-ms-landing-testimonial-card__stars {
  display: flex;
  gap: rem-convert($andes-spacing-4);
  margin-bottom: rem-convert($andes-spacing-64);
}

.ui-ms-landing-testimonial-card__text {
  color: $andes-black;
  display: -webkit-box;
  font-size: rem-convert($font-size-24);
  font-weight: $font-weight-regular;
  line-height: $mshops-line-height-125;
  margin-bottom: rem-convert($andes-spacing-40);
  min-height: rem-convert($mshops-testimonial-card-height-150);
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.ui-ms-landing-testimonial-card__text--text-white {
  color: $andes-white;
}

.ui-ms-landing-stars--visible {
  visibility: visible;
}

.ui-ms-landing-stars--hidden {
  visibility: hidden;
}
