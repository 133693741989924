.ui-ms-landing-price-table-container {
  background-color: $andes-white;
  border-radius: rem-convert($mshops-border-radius-30);
  border: rem-convert($mshops-stroke-1) solid $mshops-color-gray-200;
  margin-inline: auto;
  max-width: rem-convert($mshops-price-table-max-width);
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.ui-ms-landing-price-table {
  overflow: hidden;
  transition: max-height 0.3s ease;
  width: 100%;
}

.ui-ms-landing-price-table__items {
  display: grid;
  grid-template-columns: rem-convert($mshops-price-table-item-width) 1fr;
}

.ui-ms-landing-price-table__item {
  align-items: center;
  display: flex;
  font-weight: $font-weight-bold;
  height: rem-convert($mshops-price-table-item-height);
  padding-bottom: rem-convert($mshops-spacing-15);
  padding-top: rem-convert($mshops-spacing-15);
}

.ui-ms-landing-price-table__item-title {
  font-size: rem-convert($font-size-20);
  justify-content: start;
  line-height: $mshops-line-height-110;
  padding-left: rem-convert($mshops-spacing-36);
  padding-right: rem-convert($andes-spacing-16);
  text-align: left;
}

.ui-ms-landing-price-table__item-value {
  background-color: $mshops-color-pink-700;
  color: $andes-white;
  font-size: rem-convert($font-size-24);
  justify-content: center;
  line-height: $mshops-line-height-120;
  padding-inline: rem-convert($mshops-spacing-30);
  text-align: center;
}

.ui-ms-landing-price-table__items:first-child
  .ui-ms-landing-price-table__item-title,
.ui-ms-landing-price-table__items:first-child
  .ui-ms-landing-price-table__item-value {
  height: rem-convert(
    calc($mshops-price-table-item-height + $andes-spacing-20)
  );
  margin-top: 0;
  padding-top: rem-convert($mshops-spacing-36);
}

.ui-ms-landing-price-table__items:last-child
  .ui-ms-landing-price-table__item-title,
.ui-ms-landing-price-table__items:last-child
  .ui-ms-landing-price-table__item-value {
  height: rem-convert(
    calc($mshops-price-table-item-height + $andes-spacing-20)
  );
  margin-top: 0;
  padding-bottom: rem-convert($mshops-spacing-36);
}

.ui-ms-landing-price-table__button {
  border-radius: rem-convert($mshops-border-radius-30);
  border: rem-convert($mshops-stroke-2) solid $andes-black;
  cursor: pointer;
  display: flex;
  font-size: rem-convert($font-size-20);
  font-weight: $font-weight-semibold;
  line-height: $mshops-line-height-110;
  margin-inline: auto;
  margin-top: rem-convert($andes-spacing-48);
  padding: rem-convert($andes-spacing-20 $andes-spacing-24);
}

.ui-ms-landing-price-table__item--fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.ui-ms-landing-price-table__item-value--icon {
  height: $andes-spacing-40;
  width: $andes-spacing-40;
}
