@media (width <= 600px) {
  .ui-ms-company-avatar {
    display: flex;
  }

  .ui-ms-company-avatar__text {
    font-size: rem-convert($font-size-20);
  }

  .ui-ms-company-avatar-line {
    margin-bottom: rem-convert($andes-spacing-16);
  }
}
