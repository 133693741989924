@import '../../../../styles/common';

@media (width <= 991px) {
  .ui-ms-landing-hero {
    padding: rem-convert(
      $mshops-spacing-168 $andes-spacing-16 $andes-spacing-64
    );
  }

  .ui-ms-landing-hero__container {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: rem-convert($andes-spacing-32);
  }

  .ui-ms-landing-hero__title {
    font-size: rem-convert($mshops-font-size-64);
  }

  .ui-ms-landing-hero__subtitle {
    font-size: rem-convert($mshops-font-size-24);
  }

  .ui-ms-landing-hero__copy {
    font-size: rem-convert($font-size-16);
    padding: 0;
  }
}
