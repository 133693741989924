@import '../../../../styles/common';

@media (width <= 600px) {
  .ui-ms-landing-create-your-shop {
    align-items: normal;
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-40;
    padding: rem-convert($andes-spacing-64 0);
  }

  .ui-ms-landing-create-your-shop__title {
    font-size: rem-convert($mshops-font-size-56);
    padding: 0 rem-convert($andes-spacing-20);
    position: relative;
  }

  .ui-ms-landing-create-your-shop__tabs--gap {
    padding: rem-convert(0 $andes-spacing-20);
  }
}
