@media (width <= 600px) {
  .ui-ms-landing-faq-accordion-item {
    border-radius: rem-convert($mshops-border-radius-15);
    gap: rem-convert($andes-spacing-16);
    padding: rem-convert($andes-spacing-16);
  }

  .ui-ms-landing-faq-accordion-item__title {
    font-size: rem-convert($andes-spacing-20);
    font-weight: $font-weight-semibold;
    line-height: $mshops-line-height-125;
  }

  .ui-ms-landing-faq-accordion-item__description {
    font-size: rem-convert($andes-spacing-16);
    line-height: $mshops-line-height-120;
    opacity: 0;
  }

  .ui-ms-landing__faq-accordion-item--open
    .ui-ms-landing__faq-accordion-item__text {
    gap: rem-convert($andes-spacing-16);
  }
}
