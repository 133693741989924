@import '../../../../styles/common';

.ui-ms-landing-estilo {
  background: $mshops-color-gray-100;
  padding: rem-convert($andes-spacing-64 0 $mshops-spacing-120);
  width: 100%;
}

.ui-ms-landing-estilo__container {
  margin: 0 auto;
  max-width: rem-convert(1366px);
  width: 100%;
}

.ui-ms-landing-estilo__content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: rem-convert($mshops-spacing-96);
  padding: 0 rem-convert($andes-spacing-40);
}

.ui-ms-landing-estilo__title-col,
.ui-ms-landing-estilo__text-col {
  width: 50%;
}

.ui-ms-landing-estilo__title {
  font-size: rem-convert($mshops-font-size-86);
  font-weight: $font-weight-black;
  max-width: rem-convert($mshops-container-480);
  text-transform: uppercase;
}

.ui-ms-landing-estilo__text {
  font-size: rem-convert($font-size-32);
  font-weight: $font-weight-regular;
  line-height: $mshops-line-height-100;
  margin-bottom: rem-convert($andes-spacing-48);
}

.ui-ms-landing-estilo__button {
  font-weight: $font-weight-bold;
}

.ui-ms-landing-estilo__cont-image {
  text-align: center;
}

.ui-ms-landing-estilo__image {
  width: 100%;
}
