.ui-ms-company-avatar {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.ui-ms-company-avatar__image {
  width: rem-convert($mshops-avatar-size);
}

.ui-ms-company-avatar__text {
  color: $andes-black;
  font-family: $font-family-primary;
  font-size: rem-convert($font-size-24);
  font-weight: $font-weight-regular;
  line-height: $mshops-line-height-120;
  margin-left: rem-convert($andes-spacing-16);
}

.ui-ms-company-avatar__text--text-white {
  color: $andes-white;
}

.ui-ms-company-avatar-line {
  background: $andes-black;
  height: rem-convert($mshops-stroke-1);
  margin-bottom: rem-convert($andes-spacing-20);
  width: 50%;
}

.ui-ms-company-avatar-line--white-line {
  background: $andes-white;
}
