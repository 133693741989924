@import '../../../../styles/common';

@media (width <= 991px) {
  .ui-ms-steps-marquee {
    background: $andes-white;
    margin: 0 0 rem-convert($andes-spacing-32);
    padding: rem-convert($andes-spacing-20) 0;
    width: 100%;
  }

  .ui-ms-steps-marquee__text {
    font-size: rem-convert($mshops-font-size-40);
  }

  .ui-ms-landing-icon--arrow-marquee {
    height: rem-convert($mshops-icon-size-40);
    margin-bottom: rem-convert($andes-spacing-8);
    width: rem-convert($mshops-icon-size-40);
  }
}
