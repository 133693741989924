@import '../../../../styles/common';

@media (width <= 991px) {
  .ui-ms-landing-tools {
    align-items: normal;
    background: $andes-black;
    padding: rem-convert(
      $mshops-spacing-96 0 $mshops-spacing-96 $andes-spacing-20
    );
    width: 100%;
  }

  .ui-ms-landing-tools__container {
    align-items: normal;
    gap: rem-convert($andes-spacing-48);
  }

  .ui-ms-landing-tools__text-container {
    gap: rem-convert($andes-spacing-24);
  }

  .ui-ms-landing-tools__title {
    font-size: rem-convert($font-size-44);
  }

  .ui-ms-landing-tools__description {
    font-size: rem-convert($font-size-16);
  }
}
