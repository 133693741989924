.seo-container {
  align-items: center;
  background: $andes-white;
  display: flex;
  flex-direction: column;
  gap: rem-convert($andes-spacing-24);
  height: rem-convert($mshops-toolcard-animation-size-315);
  justify-content: flex-end;
  padding: 0 rem-convert($andes-spacing-8);
  position: relative;
  width: 100%;
}

.seo-container__cont-text {
  background: $mshops-color-pink-700;
  border-radius: rem-convert($mshops-border-radius-30);
  color: $andes-white;
  font-size: rem-convert($font-size-24);
  font-weight: $font-weight-semibold;
  line-height: $mshops-line-height-100;
  max-width: rem-convert($mshops-toolcard-pill-size-331);
  padding: rem-convert($andes-spacing-20 $andes-spacing-40);
  text-align: center;
  width: 100%;
}

.seo-container__primary-animation {
  animation: primary 6s infinite;
  opacity: 0.5;
  transform: scale(95%);
}

.seo-container__secondary-animation {
  animation: primary 6s infinite;
  animation-delay: 2s;
  opacity: 0.5;
  transform: scale(95%);
}

.seo-container__tertiary-animation {
  animation: primary 6s infinite;
  animation-delay: 4s;
  opacity: 0.5;
  transform: scale(95%);
}

@keyframes primary {
  0% {
    opacity: 0.5;
    transform: scale(95%);
  }

  45% {
    opacity: 0.5;
    transform: scale(95%);
  }

  55% {
    opacity: 1;
    transform: scale(100%);
  }

  75% {
    opacity: 1;
    transform: scale(100%);
  }

  100% {
    opacity: 0.5;
    transform: scale(95%);
  }
}
