.ui-ms-landing-stepper {
  display: grid;
  gap: rem-convert($andes-spacing-32);
  grid-template-columns: 1fr 1fr;
  height: auto;
  max-width: rem-convert($mshops-stepper-max-width);
}

.ui-ms-landing-stepper__content-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.ui-ms-landing-stepper__images {
  flex: 0.5;
  overflow: hidden;
  position: relative;
}

.ui-ms-landing-stepper__image {
  height: 100%;
  left: 50%;
  max-height: 400px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  visibility: hidden;
}

.ui-ms-landing-stepper__image--visible {
  opacity: 1;
  visibility: visible;
}

.ui-ms-landing-stepper__image--hidden {
  opacity: 0;
  visibility: hidden;
}

.ui-ms-landing-step__item {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: rem-convert($andes-spacing-32);
  max-width: rem-convert($mshops-step-max-width);
  opacity: 1;
  padding-bottom: rem-convert($andes-spacing-24);
  position: relative;
  transition: all 0.5s ease;
}

.ui-ms-landing-step__tracker {
  background-image: url('~/app/assets/images/home/stepper-track-line-vertical-md.svg');
  background-position: center;
  background-repeat: space;
  background-size: contain;
  bottom: 0;
  height: 100%;
  left: rem-convert($mshops-spacing-26);
  position: absolute;
  top: 0;
  width: rem-convert($mshops-spacing-3);
  z-index: -1;
}

.ui-ms-landing-step__item:last-of-type .ui-ms-landing-step__tracker {
  height: 0;
}

.ui-ms-landing-step__content {
  flex: 1;
}

.ui-ms-landing-step__title {
  font-size: rem-convert($andes-spacing-20);
  font-weight: $font-weight-semibold;
  line-height: rem-convert($mshops-line-height-120);
  margin: 0;
}

.ui-ms-landing-step__item:not(.ui-ms-landing-step__item--active) {
  opacity: 0.5;
}

.ui-ms-landing-step__button {
  background: transparent;
  border: none;
  border-bottom: rem-convert(1px) solid $mshops-color-blue-100;
  border-radius: 0;
  color: $mshops-color-blue-100;
  cursor: pointer;
  display: inline-block;
  font-size: rem-convert($andes-spacing-20);
  font-weight: $font-weight-regular;
  height: auto;
  line-height: rem-convert($mshops-line-height-125);
  margin: 0;
  padding: 0;
}

.ui-ms-landing-step__button:hover {
  background-color: transparent;
}

@media (width >= 1600px) {
  .ui-ms-landing-step__item {
    padding-bottom: rem-convert($andes-spacing-48);
  }

  .ui-ms-landing-step__button {
    font-size: rem-convert($andes-spacing-24);
  }

  .ui-ms-landing-step__title {
    font-size: rem-convert($andes-spacing-24);
  }

  .ui-ms-landing-step__tracker {
    background-image: url('~/app/assets/images/home/stepper-track-line-vertical.svg');
    left: rem-convert($andes-spacing-32);
  }

  .ui-ms-landing-stepper__image {
    max-height: unset;
  }
}
