@import '../../../../styles/common';

@media (width <= 600px) {
  .ui-ms-landing-ecosystem {
    height: 100vh;
  }

  .ui-ms-landing-ecosystem__texts {
    gap: rem-convert($andes-spacing-32);
  }

  .ui-ms-landing-ecosystem__title {
    font-size: rem-convert($mshops-font-size-56);
    line-height: rem-convert($mshops-line-height-100);
  }

  .ui-ms-landing-ecosystem__subtitle {
    font-size: rem-convert($mshops-font-size-24);
    line-height: rem-convert($mshops-line-height-125);
    max-width: rem-convert($mshops-ecosystem-subtitle-max-width-mobile);
  }

  .ui-ms-landing-ecosystem .ui-ms-landing-card {
    border-radius: rem-convert($mshops-border-radius-15);
    height: rem-convert($mshops-ecosystem-card-size-mobile);
    width: rem-convert($mshops-ecosystem-card-size-mobile);
  }

  /* card 1 */
  .ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(1) {
    left: 5%;
    top: 8%;
    z-index: 3;
  }

  /* card 2 */
  .ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(2) {
    left: initial;
    right: 4%;
    top: 4%;
    z-index: 4;
  }

  /* card 3 */
  .ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(3) {
    left: -4%;
    top: 65%;
    z-index: 5;
  }

  /* card 4 */
  .ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(4) {
    bottom: 3%;
    left: 24%;
    top: initial;
    z-index: 2;
  }

  /* card 5 */
  .ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(5) {
    left: initial;
    right: 3%;
    top: 68%;
    z-index: 2;
  }
}
