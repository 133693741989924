@import '../../../../styles/common';

.ui-ms-landing-chart {
  align-items: center;
  background-color: $mshops-color-gray-100;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ui-ms-landing-chart__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $andes-spacing-48;
  margin: 0 auto;
  max-width: $mshops-container-1366;
  padding: rem-convert($mshops-spacing-168 $andes-spacing-48);
  text-align: center;
}

.ui-ms-landing-chart__title {
  font-size: rem-convert($mshops-font-size-86);
  font-weight: $font-weight-black;
  line-height: $line-height-xs;
  text-transform: uppercase;
}

.ui-ms-landing-chart__copy {
  font-size: rem-convert($font-size-32);
  font-weight: $font-weight-regular;
  line-height: $mshops-line-height-125;
  padding: rem-convert(0 $andes-spacing-64);
}
