@media (width <= 600px) {
  .ui-ms-landing-icon-button {
    height: rem-convert($andes-spacing-32);
    width: rem-convert($andes-spacing-32);
  }

  .ui-ms-landing-icon-button__icon {
    height: rem-convert($andes-spacing-16);
    width: rem-convert($andes-spacing-16);
  }
}
