@import '~@andes/common/index';
@import '~sass-rem';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: $font-size-16 !important;
}

body {
  font-family: $font-family-primary;
  background: $andes-white;
}
