@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

.ui-ms-landing-carousel__safe--gap .slick-slide {
  padding: rem-convert(0 $andes-spacing-20);
}

.ui-ms-landing-carousel .slick-prev,
.ui-ms-landing-carousel .slick-next {
  z-index: 3;
}

.ui-ms-landing-carousel .slick-prev {
  left: 4%;
}

.ui-ms-landing-carousel .slick-next {
  right: 4%;
}

.ui-ms-landing-carousel .slick-prev:hover,
.ui-ms-landing-carousel .slick-next:hover {
  background: $mshops-color-yellow-600-30;
  color: transparent;
  outline: none;
}

.ui-ms-landing-carousel .slick-next:focus,
.ui-ms-landing-carousel .slick-prev:focus {
  background-color: $mshops-color-white-30;
}

.ui-ms-landing-carousel .slick-prev::before,
.ui-ms-landing-carousel .slick-next::before {
  content: '';
  font-family: unset;
}

.ui-ms-landing-carousel .slick-disabled {
  display: block;
}

/* Custom Slick Dots */

.slick-dots {
  bottom: rem-convert(-$andes-spacing-40);
  display: flex;
  flex-direction: row;
  gap: rem-convert($andes-spacing-4);
  justify-content: center;
  transition: width 0.4s;
}

.slick-dots li {
  align-items: center;
  display: flex;
  height: rem-convert($andes-spacing-4);
  margin: 0;
  transition: width 0.3s ease-out;
  width: rem-convert($andes-spacing-8);
}

.slick-dots .slick-active {
  width: rem-convert($andes-spacing-28);
}

.ui-ms-landing-carousel__dots--custom {
  background-color: $mshops-color-gray-150;
  border-radius: rem-convert($andes-spacing-4);
  height: rem-convert($andes-spacing-4);
  position: relative;
  width: rem-convert($andes-spacing-8);
}

.slick-dots .slick-active .ui-ms-landing-carousel__dots--custom {
  background-color: $mshops-color-gray-900;
  overflow: hidden;
  top: 0;
  width: rem-convert($andes-spacing-64);
}

.ui-ms-landing-carousel__dark
  .slick-dots
  .slick-active
  .ui-ms-landing-carousel__dots--custom {
  background-color: $andes-white;
}

.ui-ms-landing-carousel__light
  .slick-dots
  .slick-active
  .ui-ms-landing-carousel__dots--custom {
  background-color: $mshops-color-gray-900;
}
