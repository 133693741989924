@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.eot');
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.eot?#iefix')
      format('embedded-opentype'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.eot');
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.eot?#iefix')
      format('embedded-opentype'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.ttf')
      format('truetype');
}
