.biolibre {
  align-items: center;
  background: $mshops-color-gray-200;
  display: flex;
  flex-direction: column;
  height: rem-convert($mshops-toolcard-animation-size-315);
  justify-content: center;
  position: relative;
  width: 100%;
}

.biolibre__cont-image {
  align-items: center;
  display: flex;
  height: rem-convert($mshops-toolcard-animation-size-315);
  justify-content: center;
  position: absolute;
  width: 100%;
}

.biolibre__image {
  align-self: flex-end;
  margin: 0 auto;
  max-width: rem-convert($mshops-toolcard-image-size-267);
  width: 100%;
}

.biolibre__cont-text {
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  background: rgba(255 255 255 / 90%);
  border-radius: rem-convert($mshops-border-radius-30);
  border: rem-convert($mshops-stroke-1) solid $mshops-color-pink-700;
  padding: rem-convert($andes-spacing-20 $andes-spacing-40);
  z-index: 1;
}

.biolibre__text {
  border-right: rem-convert($mshops-stroke-2) solid rgba(0 0 0 / 75%);
  color: $mshops-color-gray-800;
  font-size: rem-convert($font-size-24);
  font-weight: $font-weight-semibold;
  line-height: $mshops-line-height-100;
  overflow: hidden;
  position: relative;
  width: 101%;
}

.biolibre__typewriter-animation {
  animation: typewriter 1.5s steps(44) 1s 1 normal both,
    blink-cursor 600ms steps(44) infinite normal;
  animation-delay: 1s;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 101%;
  }
}

@keyframes blink-cursor {
  from {
    border-right-color: rgba(0 0 0 / 75%);
  }

  to {
    border-right-color: transparent;
  }
}

.biolibre__image-animation {
  animation: animate-image 0.71s normal;
  animation-timing-function: ease-in-out;
  transform: translateY(10px);
}

.biolibre__pill-animation {
  animation: animate-image 1s normal;
  animation-timing-function: ease-in-out;
  transform: translateY(10px);
}

@keyframes animate-image {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  40% {
    opacity: 0.5;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}
