@import '../../../../styles/common';

.ui-ms-steps-marquee {
  background: $andes-white;
  margin: rem-convert($andes-spacing-40 0 $mshops-spacing-80);
  padding: rem-convert($andes-spacing-20 0);
  width: 100%;
}

.ui-ms-steps-marquee__container {
  align-items: center;
  display: flex;
}

.ui-ms-steps-marquee__text {
  color: $andes-black;
  font-size: rem-convert($mshops-font-size-80);
  font-weight: $font-weight-black;
  line-height: $mshops-line-height-100;
  margin: 0 rem-convert($andes-spacing-20);
  opacity: 0.1;
  text-transform: uppercase;
}

.ui-ms-landing-icon--arrow-marquee {
  height: rem-convert($mshops-icon-number-size-70);
  margin-bottom: rem-convert($andes-spacing-16);
  opacity: 0.7;
  width: rem-convert($mshops-icon-number-size-70);
}

.rfm-marquee-container {
  overflow-y: hidden;
}
