@media (width <= 600px) {
  .ui-ms-landing-tabs__tab {
    font-size: rem-convert($andes-spacing-16);
    padding: rem-convert($andes-spacing-12 $andes-spacing-16);
    white-space: wrap;
  }

  .ui-ms-landing-tabs {
    align-items: normal;
  }
}
