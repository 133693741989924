@import '../../../../styles/common';

.ui-ms-landing-ecosystem {
  align-items: center;
  background-color: $mshops-color-gray-100;
  display: flex;
  height: rem-convert($mshops-ecosystem-height);
  justify-content: center;
  position: relative;
}

.ui-ms-landing-ecosystem__texts {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: rem-convert($andes-spacing-48);
  text-transform: uppercase;
}

.ui-ms-landing-ecosystem__title {
  align-items: center;
  font-size: rem-convert($mshops-font-size-86);
  font-weight: $font-weight-black;
  line-height: $mshops-line-height-100;
  max-width: rem-convert($mshops-ecosystem-title-max-width);
  text-align: center;
}

.ui-ms-landing-ecosystem__subtitle {
  align-items: center;
  font-size: rem-convert($font-size-32);
  font-weight: $font-weight-black;
  line-height: $mshops-line-height-125;
  max-width: rem-convert($mshops-ecosystem-subtitle-max-width-desktop);
  text-align: center;
}

.ui-ms-landing-ecosystem__card-container {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.ui-ms-landing-ecosystem .ui-ms-landing-card {
  align-items: center;
  border-radius: rem-convert($mshops-border-radius-30);
  display: flex;
  height: rem-convert($mshops-ecosystem-card-size-desktop);
  justify-content: center;
  position: absolute;
  transition: all 1s 0.3s;
  width: rem-convert($mshops-ecosystem-card-size-desktop);
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(1) {
  left: 20%;
  top: 34%;
  z-index: 3;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(2) {
  left: 24%;
  top: 45%;
  z-index: 4;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(3) {
  left: 30%;
  top: 50%;
  z-index: 5;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(4) {
  left: 41%;
  top: 53%;
  z-index: 2;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(5) {
  left: 55%;
  top: 50%;
  z-index: 2;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(6) {
  left: 55%;
  top: 50%;
  z-index: 2;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(7) {
  left: 62%;
  top: 42%;
  z-index: 1;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(8) {
  left: 62%;
  top: 33%;
  z-index: 6;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(9) {
  left: 50%;
  top: 32%;
  z-index: 1;
}

.ui-ms-landing-ecosystem__card-container .ui-ms-landing-card:nth-child(10) {
  left: 36%;
  top: 34%;
  z-index: 6;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(1) {
  left: 3%;
  top: 5%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(2) {
  left: 5%;
  top: 40%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(4) {
  left: 37%;
  top: 75%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(3) {
  left: 13%;
  top: 70%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(5) {
  left: 56%;
  top: 73%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(6) {
  left: 76%;
  top: 71%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(7) {
  left: 83%;
  top: 35%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(8) {
  left: 70%;
  top: 8%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(9) {
  left: 50%;
  top: 5%;
}

.ui-ms-landing-ecosystem--visible
  .ui-ms-landing-ecosystem__card-container
  .ui-ms-landing-card:nth-child(10) {
  left: 22%;
  top: 7%;
}
