@media (width <= 600px) {
  .ui-ms-landing-floating-card--md,
  .ui-ms-landing-floating-card--lg {
    width: rem-convert($mshops-floatingcard-136);
  }

  .ui-ms-landing-floating-card--sm {
    width: rem-convert($mshops-floatingcard-113);
  }
}
