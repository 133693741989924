.ui-ms-landing-faq-accordion {
  display: flex;
  flex-direction: column;
  gap: rem-convert($andes-spacing-16);
  max-width: rem-convert($mshops-faq-accordion-max-width);
}

.ui-ms-landing-faq-accordion-item {
  align-items: center;
  border-radius: rem-convert($mshops-border-radius-15);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: rem-convert($andes-spacing-32);
  justify-content: space-between;
  overflow: hidden;
  padding: rem-convert($andes-spacing-16 $andes-spacing-32);
  transition: background-color 0.1s ease-out;
}

.ui-ms-landing-faq-accordion-item__text {
  display: flex;
  flex-direction: column;
}

.ui-ms-landing-faq-accordion-item__title {
  font-size: rem-convert($andes-spacing-24);
  font-weight: $font-weight-semibold;
  line-height: $mshops-line-height-125;
}

.ui-ms-landing-faq-accordion-item__description {
  font-size: rem-convert($andes-spacing-20);
  font-weight: $font-weight-semibold;
  line-height: $mshops-line-height-120;
  opacity: 0;
}

.ui-ms-landing-faq-accordion-item__content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.ui-ms-landing-faq-accordion-item--open {
  background-color: $mshops-color-yellow-600;
  padding: rem-convert($andes-spacing-32);
  transition: all 0.3s ease-out;
}

.ui-ms-landing-faq-accordion-item__arrow-icon {
  height: rem-convert($andes-spacing-32);
  transform: rotate(-180deg);
  transition: transform 0.3s ease-out;
}

.ui-ms-landing-faq-accordion-item--open
  .ui-ms-landing-faq-accordion-item__arrow-icon {
  height: rem-convert($andes-spacing-48);
  transform: rotate(0deg);
  transition: transform 0.3s ease-out;
}

.ui-ms-landing-faq-accordion-item--open
  .ui-ms-landing-faq-accordion-item__text {
  gap: rem-convert($andes-spacing-24);
}

.ui-ms-landing-faq-accordion-item--open
  .ui-ms-landing-faq-accordion-item__title {
  font-weight: $font-weight-bold;
}

.ui-ms-landing-faq-accordion-item--open
  .ui-ms-landing-faq-accordion-item__description {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
