@media (width <= 600px) {
  .ui-ms-landing-card-with-cta__container-bg {
    background: linear-gradient(
      180deg,
      rgba(0 0 0 / 15%) 0%,
      $andes-black 100%
    );
    max-height: rem-convert($mshops-card-cta-height-mobile);
    min-height: auto;
    padding: rem-convert($andes-spacing-16);
  }

  .ui-ms-landing-card-with-cta__title {
    font-size: rem-convert($font-size-18);
    margin-bottom: rem-convert($andes-spacing-16);
  }

  .ui-ms-landing-card-with-cta__description {
    font-size: rem-convert($font-size-16);
    margin-bottom: rem-convert($andes-spacing-16);
  }
}
