@import '../../../../styles/common';

.faq {
  background: $andes-white;
  padding: rem-convert($andes-spacing-64 0 $mshops-spacing-120);
  width: 100%;
}

.faq__container {
  margin: 0 auto;
  max-width: rem-convert($mshops-faq-accordion-max-width);
  width: 100%;
}

.faq__title {
  font-size: rem-convert($mshops-font-size-56);
  font-weight: $font-weight-bold;
  line-height: $mshops-line-height-100;
  margin: rem-convert(0 0 $mshops-spacing-100);
  text-align: center;
}
